<template>
    <section class="bg-bdy">
        <div class="bg-white"><!-- Start: banner -->
            <div style="min-height: 200px;">
                <div class="container">
                    <div class="row g-0"><!-- Start: big banner -->
                        <div class="col-lg-8">
                            <div class="h-100 full-height">
                                <swiper :keyboard="{
      enabled: true,
    }" :modules="[Autoplay,Navigation,Pagination,Keyboard]" :slides-per-view="1" :navigation="true" :autoplay="{
          delay: 2500,
            disableOnInteraction: false,
        }" class="template-swipper"><swiper-slide v-for="(carouselBanners,index) in ((webMedias.carouselBanners||[]).filter((itss,itx) => itx<6))" v-bind:key="index"><nuxt-link-locale class="a-decoration-none" aria-label="chương trình và sự kiện" :to="carouselBanners.itemLink">
                                            <div class="pointer px-1 h-100 pt-1"><b-img class="img-fluid h-100" alt="banner lớn" :src="carouselBanners.itemImage" lazy></b-img></div>
                                        </nuxt-link-locale></swiper-slide></swiper>
                            </div>
                        </div><!-- End: big banner -->
                        <div class="col-lg-4 d-none d-lg-block">
                            <div class="h-100"><!-- Start: banner trên -->
                                <div class="pointer h-50 pt-1" v-for="(widthBanners,index) in (webMedias.widthBanners)" v-bind:key="index"><nuxt-link-locale aria-label="khuyến mãi" :to="widthBanners.itemLink"><b-img class="img-fluid w-100" alt="banner phụ" lazy :src="widthBanners.itemImage"></b-img></nuxt-link-locale></div><!-- End: banner trên -->
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- End: banner --><!-- Start: danh muc -->
            <div class="box">
                <div class="container"><!-- Start: cha -->
                    <div class="d-flex flex-row justify-content-around align-items-center py-3 mb-3 home-menu"><!-- Start: danhmuc1 -->
                        <div class="p-2 me-2 item-home-menu" v-for="(listHomeMenu,index) in listHomeMenus" v-bind:key="listHomeMenu._id">
                            <div><nuxt-link-locale class="text-decoration-none text-dark" aria-label="Đường dẫn bổ sung" :to="listHomeMenu.link">
                                    <div class="d-flex justify-content-center mb-1"><!-- Start: img -->
                                        <div class="d-flex justify-content-center item-category-img-top item-transform-img"><b-img class="w-100 h-100" alt="icon đường dẫn bổ sung" :src="listHomeMenu.icon" lazy></b-img></div><!-- End: img -->
                                    </div>
                                    <div class="d-flex flex-column align-items-center"><span class="d-inline-flex fs-12">{{listHomeMenu.label}}</span></div>
                                </nuxt-link-locale></div>
                        </div><!-- End: danhmuc1 -->
                    </div><!-- End: cha -->
                </div>
            </div><!-- End: danh muc -->
        </div><!-- Start: all homeSection -->
        <div class="mb-3"><!-- Start: home section -->
            <section class="mt-3" v-for="(listHomeSections,index) in listHomeSectionss" v-bind:key="listHomeSections._id">
                <div class="container">
                    <div class="bg-section primary-box-shadow">
                        <div class="row g-0">
                            <div class="col p-2"><!-- Start: cha -->
                                <div class="h-100"><!-- Start: section-time -->
                                    <div class="d-flex pt-2"><!-- Start: name -->
                                        <div class="d-flex justify-content-center align-items-center px-2">
                                            <h4 class="pm-0">{{listHomeSections.sectionName}}</h4>
                                        </div><!-- End: name --><!-- Start: time -->
                                        <div class="d-flex justify-content-center align-items-center text-white d-none"><vue-countdown v-slot="{ days, hours, minutes, seconds, milliseconds }">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-center align-items-center bg-time"><!-- Start: Ngày --><span class="mb-0">00</span><!-- End: Ngày --></div><span class="mx-2">:</span>
                                                    <div class="d-flex justify-content-center align-items-center bg-time"><!-- Start: Giờ --><span class="mb-0">hours</span><!-- End: Giờ --></div><span class="mx-2">:</span>
                                                    <div class="d-flex justify-content-center align-items-center bg-time"><!-- Start: Phút --><span class="mb-0">minutes</span><!-- End: Phút --></div><span class="mx-2">:</span>
                                                    <div class="d-flex justify-content-center align-items-center bg-time"><!-- Start: Giây --><span class="mb-0">seconds</span><!-- End: Giây --></div>
                                                </div>
                                            </vue-countdown></div><!-- End: time -->
                                    </div><!-- End: section-time -->
                                </div><!-- End: cha -->
                            </div>
                            <div class="col p-2">
                                <div class="d-flex justify-content-end align-items-center pe-2 h-100 pointer"><nuxt-link-locale class="text-decoration-none text-dark pt-2" aria-label="chi tiết bộ sưu tập" :to="'/bo-suu-tap/'+listHomeSections.slug">
                                        <p class="pm-0 hover-all-section">Xem tất cả<i class="fa fa-angle-right ms-2"></i></p>
                                    </nuxt-link-locale></div>
                            </div>
                        </div>
                        <div class="w-100 px-2 pb-2">
                            <swiper :keyboard="{
      enabled: true,
    }" :modules="[Autoplay,Navigation,Pagination,Keyboard]" :slides-per-view="5" :navigation="true" :autoplay="{
          delay: 2500,
            disableOnInteraction: false,
        }" class="template-swipper h-100"><swiper-slide style="height: auto;" v-for="(listProducts,index) in listHomeSections?.listProducts||[]" v-bind:key="listProducts"><nuxt-link-locale class="text-decoration-none text-dark" aria-label="sản phẩm thuộc BST" :to="'/san-pham/'+listProducts.slug">
                                        <div class="h-100 p-1"><!-- Start: product -->
                                            <div class="d-flex flex-column item-block-sp pointer h-100"><!-- Start: img -->
                                                <div>
                                                    <div class="img-thumb-sp item-transform-img"><!-- Start: thay thế --><img class="w-100 h-100" alt="icon-flashsale" src="/static/cosmeticscz/no-image-news.png" v-if="listProducts.image===undefined || listProducts.image===null || listProducts.image===''"><!-- End: thay thế --><img class="w-100 h-100" v-else :src="listProducts.image" :alt="listProducts.name"></div>
                                                </div><!-- End: img -->
                                                <div class="px-2 pb-2"><!-- Start: Basic Price -->
                                                    <div class="d-flex justify-content-between pt-1" v-if="listProducts.marketPrice===null || listProducts.marketPrice===listProducts.price || listProducts.marketPrice===0">
                                                        <p class="pm-0 tx-price">{{formatNumberMoney(listProducts?.price)}}</p>
                                                    </div><!-- End: Basic Price --><!-- Start: Discount Price -->
                                                    <div class="d-flex justify-content-between align-items-center pt-1" v-if="(listProducts.marketPrice!==null)&&(listProducts.marketPrice!==0)&&(listProducts.marketPrice!==listProducts.price)">
                                                        <p class="pm-0 tx-price">{{formatNumberMoney(listProducts?.price)}}</p>
                                                        <p class="pm-0 tx-price-reduce">{{formatNumberMoney(listProducts?.marketPrice)}}</p>
                                                    </div><!-- End: Discount Price --><!-- Start: brand -->
                                                    <div class="item-card-brand"><span>{{listProducts?.productBrands?.name}}</span></div><!-- End: brand --><!-- Start: tên -->
                                                    <div class="two-line-truncate item-name-product">
                                                        <p class="pm-0">{{listProducts?.name}}</p>
                                                    </div><!-- End: tên --><!-- Start: rating and bought count -->
                                                    <div class="d-flex justify-content-between align-items-center ratting-bought-count pt-1"><!-- Start: ratting -->
                                                        <div class="ratting"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i></div><!-- End: ratting --><!-- Start: bought-count -->
                                                        <div class="bought-count"><i class="icon-basket-loaded me-1"></i><span>{{listProducts?.sellcount}}</span></div><!-- End: bought-count -->
                                                    </div><!-- End: rating and bought count -->
                                                </div><!-- Start: phần trăm giảm giá -->
                                                <div class="price-discount px-2 py-1" v-if="(listProducts.marketPrice!==null)&&(listProducts.marketPrice!==0)&&(listProducts.marketPrice!==listProducts.price)"><span>{{round(((listProducts?.marketPrice-listProducts?.price)/listProducts?.marketPrice)*100,0)}}</span><span>%</span></div><!-- End: phần trăm giảm giá -->
                                            </div><!-- End: product -->
                                        </div>
                                    </nuxt-link-locale></swiper-slide></swiper>
                        </div>
                    </div>
                </div>
            </section><!-- End: home section -->
        </div><!-- End: all homeSection --><!-- Start: box-category -->
        <div class="mb-3">
            <div class="container"><!-- Start: cha -->
                <div class="bg-white p-2 border-rd-8 primary-box-shadow">
                    <div class="p-2">
                        <h5>{{$t('danhMuc')}}</h5>
                    </div>
                    <swiper :keyboard="{
      enabled: true,
    }" :modules="[Autoplay,Navigation,Pagination,Keyboard]" :slides-per-view="9" :space-between="16" :navigation="false" :autoplay="{
          delay: 2500,
            disableOnInteraction: false,
        }" class="template swiper"><!-- Start: mặt nạ --><swiper-slide style="width:calc((100% - 128px) /9);margin-right:16px" v-for="(groupProductLv1,index) in groupProductLv1s" v-bind:key="groupProductLv1._id"><nuxt-link-locale class="text-decoration-none text-dark" aria-label="Danh mục nổi bật" :to="'/danh-muc/'+groupProductLv1.slug">
                                <div class="undefined g5o52US4gYy9sjJVcS8A4S-preview-css-ldr con"><!-- Start: block-category -->
                                    <div class="item-category pointer"><!-- Start: img -->
                                        <div class="item-category-img bd-rs-4"><img class="img-fluid w-100" alt="danh mục cấp 1" v-if="(groupProductLv1.thumbnail!==undefined)&&(groupProductLv1.thumbnail!=='')&&(groupProductLv1.thumbnail!==null)" :src="groupProductLv1.thumbnail" :alt="'Ảnh đại diện danh mục '+groupProductLv1.name"><!-- Start: img thay thế --><img class="img-fluid w-100" src="/static/cosmeticscz/no-image-news.png" alt="ảnh tạm cho danh mục" v-if="groupProductLv1.thumbnail==='' || groupProductLv1.thumbnail===undefined || groupProductLv1.thumbnail===null"><!-- End: img thay thế --></div><!-- End: img -->
                                        <div class="d-flex justify-content-center item-name-category two-line-truncate p-1 fs-14"><span>{{groupProductLv1.name}}</span></div>
                                    </div><!-- End: block-category -->
                                </div>
                            </nuxt-link-locale></swiper-slide><!-- End: mặt nạ --></swiper>
                </div><!-- End: cha -->
            </div>
        </div><!-- End: box-category --><!-- Start: bán chạy -->
        <div class="mb-3">
            <div class="container"><!-- Start: cha -->
                <div class="bg-white p-2 border-rd-8 primary-box-shadow">
                    <div class="p-2">
                        <h5>{{$t('banChay')}}</h5>
                    </div>
                    <swiper :keyboard="{
      enabled: true,
    }" :modules="[Autoplay,Navigation,Pagination,Keyboard]" :slides-per-view="5" :space-between="2" :navigation="false" :autoplay="{
          delay: 2500,
            disableOnInteraction: false,
        }" class="template-swipper"><swiper-slide style="width:calc((100% - 8px) /5);margin-right:2px" v-for="(productBanChay,index) in productBanChays" v-bind:key="productBanChay._id">
                            <div class="p-1">
                                <div class="item-block-sale pointer tex"><!-- Start: img -->
                                    <div class="img-ban-chay item-transform-img"><!-- Start: product-card -->
                                        <div class="product-card pointer item-transform-img h-100 p-1 p-lg-2"><nuxt-link-locale class="text-decoration-none text-dark" aria-label="sản phẩm bán chạy" :to="'/san-pham/'+productBanChay.slug">
                                                <div class="item-card-img"><img class="img-fluid w-100" :src="productBanChay.image" :alt="productBanChay.name"></div>
                                                <div class="item-info-card pt-1"><!-- Start: Basic Price -->
                                                    <div class="d-flex justify-content-between item-card-price" v-if="productBanChay.marketPrice===productBanChay.price || productBanChay.marketPrice===null || productBanChay.marketPrice===0">
                                                        <div><span>{{formatNumberMoney(productBanChay.price)}}</span></div>
                                                    </div><!-- End: Basic Price --><!-- Start: discount price -->
                                                    <div class="d-flex flex-column justify-content-between flex-md-row item-card-price h-100" v-if="(productBanChay.marketPrice!==productBanChay.price)&&(productBanChay.marketPrice!==null)&&(productBanChay.marketPrice!==0)">
                                                        <div class="pb-1 pb-md-0"><span>{{formatNumberMoney(productBanChay.price)}}</span></div>
                                                        <div class="d-flex align-items-center"><!-- Start: giá cũ d-none --><span class="old-price">{{formatNumberMoney(productBanChay.marketPrice)}}</span><!-- End: giá cũ d-none -->
                                                            <div class="d-flex justify-content-center align-items-center percen-discount h-100 py-1"><span>{{round(((productBanChay.marketPrice-productBanChay.price)/productBanChay.marketPrice)*100,0)}}</span><span>%</span></div>
                                                        </div>
                                                    </div><!-- End: discount price --><!-- Start: brand -->
                                                    <div class="item-card-brand mb-1"><span>{{productBanChay.productBrands?.name}}</span></div><!-- End: brand --><!-- Start: name -->
                                                    <div class="name-div cs-pt mb-1"><span class="name text-truncate-2">{{productBanChay.name}}</span></div><!-- End: name --><!-- Start: rating and bought count -->
                                                    <div class="d-flex justify-content-between align-items-center h-100 ratting-bought-count"><!-- Start: ratting -->
                                                        <div class="ratting"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i></div><!-- End: ratting --><!-- Start: bought-count -->
                                                        <div class="bought-count"><i class="icon-basket-loaded me-1"></i><span>{{productBanChay.sellcount}}</span></div><!-- End: bought-count -->
                                                    </div><!-- End: rating and bought count -->
                                                </div>
                                            </nuxt-link-locale></div><!-- End: product-card -->
                                    </div><!-- End: img --><!-- Start: title -->
                                    <div class="d-flex flex-column align-items-center p-2 position-absolute bg-opacity-sale w-100 d-none" style="bottom: 0;min-height: 36px;">
                                        <p class="fs-14 two-line-truncate text-capitalize">Tên sản phẩm</p>
                                        <div class="d-flex hover-all"><span class="fs-14 me-2 text-capitalize">sl đã bán bán</span><span class="fs-14" style="font-weight:500">Sản phẩm đã bán</span></div>
                                    </div><!-- End: title -->
                                </div>
                            </div>
                        </swiper-slide></swiper>
                </div><!-- End: cha -->
            </div>
        </div><!-- End: bán chạy --><!-- Start: Thương hiệu -->
        <div class="mb-3">
            <div class="container">
                <div class="bg-white p-2 border-rd-8 primary-box-shadow"><!-- Start: title -->
                    <div class="d-flex justify-content-between align-items-center p-2">
                        <h5>{{$t('thuongHieu')}}</h5><nuxt-link-locale class="text-decoration-none text-dark" to="/thuong-hieu" aria-label="danh sách thương hiệu">
                            <p class="pm-0 hover-all">Xem tất cả<i class="icon-arrow-right ms-2 fs-12"></i></p>
                        </nuxt-link-locale>
                    </div><!-- End: title --><!-- Start: content -->
                    <div class="row"><!-- Start: left -->
                        <div class="col-lg-4">
                            <div class="h-100">
                                <swiper :keyboard="{
      enabled: true,
    }" :modules="[Autoplay,Navigation,Pagination,Keyboard]" :slides-per-view="1" :navigation="true" :autoplay="{
          delay: 2500,
            disableOnInteraction: false,
        }" class="template-swipper"><swiper-slide v-for="(productBrands,index) in productBrandss" v-bind:key="productBrands._id"><nuxt-link-locale aria-label="thương hiệu nổi bật" :to="'/thuong-hieu/'+productBrands.slug">
                                            <div class="item-left-brand pointer h-100">
                                                <div class="item-brand-img"><img class="img-fluid w-100 h-100" alt="anh-thuong-hieu" :src="productBrands.thumbnail"></div>
                                            </div>
                                        </nuxt-link-locale></swiper-slide></swiper>
                            </div>
                        </div><!-- End: left --><!-- Start: right -->
                        <div class="col-lg-8"><!-- Start: cha -->
                            <div class="h-100">
                                <div class="row g-0">
                                    <div class="col-lg-3 pointer mb-2 px-2" v-for="(productBrands,index) in (productBrandss||[]).filter((itss,itx) => itx<8)" v-bind:key="productBrands._id"><nuxt-link-locale class="text-decoration-none text-dark" aria-label="thương hiệu nổi bật" :to="'/thuong-hieu/'+productBrands.slug">
                                            <div><!-- Start: img -->
                                                <div class="item-right-brand">
                                                    <div class="item-brand-img-r"><b-img :src="productBrands.thumbnail" lazy :alt="'Hình ảnh minh họa của thương hiệu'+productBrands.name"></b-img></div>
                                                    <div class="img-brand-small">
                                                        <div class="img-small-br"><b-img :src="productBrands.banner" lazy :alt="'Logo của thương hiệu'+productBrands.name"></b-img></div>
                                                    </div>
                                                </div><!-- End: img -->
                                            </div>
                                        </nuxt-link-locale></div>
                                </div>
                            </div><!-- End: cha -->
                        </div><!-- End: right -->
                    </div><!-- End: content -->
                </div>
            </div>
        </div><!-- End: Thương hiệu --><!-- Start: gợi ý -->
        <div>
            <div class="container">
                <div class="d-flex justify-content-center p-2">
                    <h5 class="fs-27">{{$t('sanPhamGoiYDanhChoBan')}}</h5>
                </div>
                <div class="row g-0 g-2">
                    <div class="col-2" v-for="(productLienQuan,index) in productLienQuans" v-bind:key="productLienQuan._id"><!-- Start: product-card -->
                        <div class="product-card-goi-y pointer p-2 h-100 item-transform-img position-relative"><nuxt-link-locale class="text-decoration-none text-dark" aria-label="sản phẩm gợi ý" :to="'/san-pham/'+productLienQuan.slug">
                                <div class="item-card-img"><b-img class="img-fluid w-100" v-if="(productLienQuan.image!==undefined)&&(productLienQuan.image!=='')&&(productLienQuan.image!==null)" :src="productLienQuan.image" :alt="'Hỉnh ảnh của sản phẩm'+productLienQuan.name" lazy></b-img><!-- Start: limg lỗi --><img class="img-fluid" alt="img mô tả" src="/static/cosmeticscz/no-image-news.png" v-if="productLienQuan.image===undefined || productLienQuan.image==='' || productLienQuan.image===null"><!-- End: limg lỗi --></div>
                                <div class="item-info-card"><!-- Start: basic price -->
                                    <div class="d-flex justify-content-between" v-if="productLienQuan.marketPrice===productLienQuan.price || productLienQuan.marketPrice===null || productLienQuan.marketPrice===0"><span class="tx-price">{{formatNumberMoney(productLienQuan.price)}}</span></div><!-- End: basic price --><!-- Start: discount-price -->
                                    <div class="d-flex justify-content-between" v-if="(productLienQuan.marketPrice!==productLienQuan.price)&&(productLienQuan.marketPrice!==null)&&(productLienQuan.marketPrice!==0)"><span class="tx-price">{{formatNumberMoney(productLienQuan.price)}}</span>
                                        <div><!-- Start: giá cũ d-none --><span class="strikethrough fs-14">{{formatNumberMoney(productLienQuan.marketPrice)}}</span><!-- End: giá cũ d-none --></div>
                                    </div><!-- End: discount-price --><!-- Start: brand -->
                                    <div class="item-card-brand"><span>{{productLienQuan.productBrands?.name}}</span></div><!-- End: brand -->
                                    <div class="item-card-name py-1"><!-- Start: vn-name -->
                                        <div class="one-line-truncate d-none"><span>[Mua 2 Tặng 1] Sữa Chống Nắng Sunplay Sáng Mịn 25g + Nước Tẩy Trang Hada Labo Dưỡng Ẩm 240ml Tặng Kem Rửa Mặt Hada Labo Dưỡng Ẩm 80g</span></div><!-- End: vn-name --><!-- Start: name -->
                                        <div class="two-line-truncate item-name-product"><span>{{productLienQuan.name}}</span></div><!-- End: name -->
                                    </div><!-- Start: rating and bought count -->
                                    <div class="d-flex justify-content-between align-items-center ratting-bought-count pt-1"><!-- Start: ratting -->
                                        <div class="ratting"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i></div><!-- End: ratting --><!-- Start: bought-count -->
                                        <div class="bought-count"><i class="icon-basket-loaded me-1"></i><span>{{productLienQuan.sellcount}}</span></div><!-- End: bought-count -->
                                    </div><!-- End: rating and bought count -->
                                </div>
                            </nuxt-link-locale><!-- Start: phần trăm giảm giá -->
                            <div class="price-discount px-2 py-1 fs-12" v-if="(productLienQuan.marketPrice!==productLienQuan.price)&&(productLienQuan.marketPrice!==null)&&(productLienQuan.marketPrice!==0)"><span>{{round(((productLienQuan.marketPrice-productLienQuan.price)/productLienQuan.marketPrice)*100,0)}}</span><span>%</span></div><!-- End: phần trăm giảm giá -->
                        </div><!-- End: product-card -->
                    </div>
                </div>
            </div>
        </div><!-- End: gợi ý -->
        <div class="d-flex d-xxl-flex flex-row justify-content-center align-items-center justify-content-xxl-center pt-2"><b-pagination class="custom-pagination" v-model="page" :total-rows="productCount" :per-page="18" @update:model-value="pagination7d086c650908769d20f8aeea1f5c3989d7ae76bcupdatemodelvalue"></b-pagination></div>
    </section>
</template><script setup>
    import { computed,watch } from 'vue';





    
    import {formatNumberMoney,} from '../../util';
    

            


    

    

    //replaceMome
    

    import round from 'lodash/round.js';

    


    const $device = useDevice();
    const { isMobile,isDesktop } = useDevice();
    const route = useRoute();

    
    definePageMeta({
        layout: "default",

    });
    
    


    let giay= ref(59);
    let phut= ref(0);
    let select= ref("");
    let currentLv1= ref("nothing here");
    let page= ref("");
    let mapSpBanChay= ref(new Map());;









    //   variables
    if(process.env.NODE_ENV==='development'){

    }




    const setParam =  function(key,value){
        let objParams = {...route.params};
        let objQuery = {...route.query};
        if(value===undefined){
            delete objParams[key];
        }else{
            objParams[key] = value;
        }
        router.push({
            path: route.path,
            query:objQuery,
            params:objParams
        });
    };
    const setQuery =  function(key,value){
        let objQuery = {...route.query};
        let isChange= false;
        if(value===undefined){
            isChange =true;
            delete objQuery[key];
        }else if(!Array.isArray(value)||(value.length)||(objQuery[key]&& value.length!==objQuery[key].length)){
            isChange =true;
        }
        if(isChange){
            objQuery[key] = value;
            router.push({
                path: route.path,
                query:objQuery
            });
        }
    };
    const setQuerys =  function(items,params){
        let objQuery = {...route.query};
        let isChange= false;
        items.forEach((item)=>{
            const {key,value} =item;
            if(value===undefined){
                isChange =true;
                delete objQuery[key];
            }else if(!Array.isArray(value)||(value.length)||(objQuery[key]&& value.length!==objQuery[key].length)){
                isChange =true;
            }
            if(isChange){
                objQuery[key] = value;

            }
        })

        let objParam={};
        items.forEach((item)=>{
            const {key,value} =item;
            if(value===undefined){
                isChange =true;
                delete objParam[key];
            }else if(!Array.isArray(value)||(value.length)||(objParam[key]&& value.length!==objParam[key].length)){
                isChange =true;
            }
            if(isChange){
                objParam[key] = value;

            }
        })
        if(isChange){
            router.push({
                path: params?('/danh-muc/'+params):route.path,
                query:objQuery
            });
        }

    };
    





    // computed
    

    //attributesCal 0

    


        // computed





        const clearTypeName = (api)=>{
            const funF = (obj)=>{
                if(obj){
                    console.log('obj')
                    Object.keys(obj).forEach((key)=>{
                        if(key==="__typename"){
                            delete obj.__typename;
                        } else if(Array.isArray(obj[key])){
                            obj[key].forEach((item)=>{
                                if(item)
                                    funF(item);
                            });
                        } else if(typeof (obj[key]) ==="object"&&(obj[key])){
                            if(obj[key])
                                funF(obj[key]);
                        }
                    });}
            };
            funF(api);
            return api;
        };



        //methods


    

// mutation

        

    
    const router = useRouter();
    


    
    const loadDataproductCount = async function(){
         await new Promise((re)=>{setTimeout(()=>{re(true)},70)});

let variableproductCount={limit: 18,sort :{sellcount:-1}, skip :function(){
                      let pagequery =0;
                      try{
                       let pagePTem = parseInt(route.query.page);
                        if(pagePTem>0){
                        pagequery = (pagePTem -1)* 18;
                        }
                      }catch(e){
                      pagequery =0;
                      }
                      return pagequery
                      }(), query :{status:{eq:true},}};

const resultproductCounttemp = await useAsyncQuery( gql` query    
 countProduct($query: productCountInput){
     productCount:  countProduct(query:$query)
  
}`,variableproductCount);
if(resultproductCounttemp.data&&resultproductCounttemp.data.value.productCount){
productCount.value = resultproductCounttemp.data.value.productCount};
;

    };

    
    const loadDataproductLienQuan = async function(){
         await new Promise((re)=>{setTimeout(()=>{re(true)},70)});

let variableproductLienQuans={limit: 18,sort :{sellcount:-1,createdAt:-1}, skip :function(){
                      let pagequery =0;
                      try{
                       let pagePTem = parseInt(route.query.page);
                        if(pagePTem>0){
                        pagequery = (pagePTem -1)* 18;
                        }
                      }catch(e){
                      pagequery =0;
                      }
                      return pagequery
                      }(), query :{status:{eq:true},}};

const resultproductLienQuanstemp = await useAsyncQuery( gql` query    
 products($query: productFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     productLienQuans:  products(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name image price slug productBrands{ _id  createdAt  updatedAt  name  slug  banner  thumbnail  description  status  arrayMediaPlus  widthBanner  highlight  orderNumber  } marketPrice sellcount 
        }

}`,variableproductLienQuans);
if(resultproductLienQuanstemp.data&&resultproductLienQuanstemp.data.value.productLienQuans){
productLienQuans.value = resultproductLienQuanstemp.data.value.productLienQuans};
;

    };

    
    const loadDatawebMedias = async function(){
         await new Promise((re)=>{setTimeout(()=>{re(true)},70)});

let variablewebMedias={};

const resultwebMediastemp = await useAsyncQuery( gql` query {  webMedias {
                carouselBanners{ itemImage  itemLink  label  description  } _id widthBanners{ itemImage  itemLink  label  description  } 
        } }`,variablewebMedias);
if(resultwebMediastemp.data&&resultwebMediastemp.data.value.data){
webMedias.value = {...resultwebMediastemp.data.value.data}};
;

    };

    const pagination7d086c650908769d20f8aeea1f5c3989d7ae76bcupdatemodelvalue = async function(){
        setQuery('page',page.value);await loadDataproductCount();await loadDataproductLienQuan();
    };
    





        //2 setup
        

        let listHomeMenus= ref([]);
let webMedias= ref({});
let listHomeSectionss= ref([]);
let groupProductLv1s= ref([]);
let productBanChays= ref([]);
let productBrandss= ref([]);
let productCount= ref(0);
let productLienQuans= ref([]);
let websiteConfig= ref({});

        const slug=route.params['desktop'];

let variablelistHomeMenus={sort :{orderNumber:1}, query :{displayOn:{in:["desktop"]},}};

const resultlistHomeMenus = await useAsyncQuery(gql` query    
 homeMenus($query: homeMenuFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listHomeMenus:  homeMenus(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                label slug icon link orderNumber highlight 
        }

}`,variablelistHomeMenus);
if(resultlistHomeMenus.data.value&&resultlistHomeMenus.data.value.listHomeMenus){
listHomeMenus.value = resultlistHomeMenus.data.value.listHomeMenus}

let variablewebMedias={};

const resultwebMedias = await useAsyncQuery(gql` query {  webMedias {
                carouselBanners{ itemImage  itemLink  label  description  } _id widthBanners{ itemImage  itemLink  label  description  } 
        } }`,variablewebMedias);
if(resultwebMedias.data.value&&resultwebMedias.data.value.webMedias){
webMedias.value = {...resultwebMedias.data.value.webMedias}}

let variablelistHomeSectionss={sort :{orderNumber:1}, query :{status:{eq:"active"},type:{nin:["fake-hight-light-group","hot-sale"]},}};

const resultlistHomeSectionss = await useAsyncQuery(gql` query    
 homeSectionss($query: homeSectionsFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listHomeSectionss:  homeSectionss(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                sectionName listProducts{ _id  name  image  price  groupProduct  slug  productBrands{ _id  createdAt  updatedAt  name  slug  banner  thumbnail  description  status  arrayMediaPlus  widthBanner  highlight  orderNumber  }  status  marketPrice  sellcount  } ngayKetThuc _id slug countingDown productsId 
        }

}`,variablelistHomeSectionss);
if(resultlistHomeSectionss.data.value&&resultlistHomeSectionss.data.value.listHomeSectionss){
//Khải ghi đè 
console.log('ListHomeSection', resultlistHomeSectionss.data.value )
listHomeSectionss.value = resultlistHomeSectionss.data.value.listHomeSectionss.map((item)=>{ return {...item,}});}

let variablegroupProductLv1s={sort :{orderNumber:1}, query :{highlight:{eq:true},status:{eq:true},}};

const resultgroupProductLv1s = await useAsyncQuery(gql` query    
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProductLv1s:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                name slug thumbnail _id highlight groupProduct status 
        }

}`,variablegroupProductLv1s);
if(resultgroupProductLv1s.data.value&&resultgroupProductLv1s.data.value.groupProductLv1s){
groupProductLv1s.value = resultgroupProductLv1s.data.value.groupProductLv1s}

let variableproductBanChays={limit: 20,sort :{sellcount:-1}, query :{status:{eq:true},sellcount:{gt:0},}};

const resultproductBanChays = await useAsyncQuery(gql` query    
 products($query: productFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     productBanChays:  products(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name image price slug productBrands{ _id  createdAt  updatedAt  name  slug  banner  thumbnail  description  status  arrayMediaPlus  widthBanner  highlight  orderNumber  } status giaOnline sellcount marketPrice 
        }

}`,variableproductBanChays);
if(resultproductBanChays.data.value&&resultproductBanChays.data.value.productBanChays){
productBanChays.value = resultproductBanChays.data.value.productBanChays}

let variableproductBrandss={sort :{orderNumber:1}, query :{highlight:{eq:true},status:{eq:true},}};

const resultproductBrandss = await useAsyncQuery(gql` query    
 productBrandss($query: productBrandsFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     productBrandss:  productBrandss(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug banner thumbnail orderNumber 
        }

}`,variableproductBrandss);
if(resultproductBrandss.data.value&&resultproductBrandss.data.value.productBrandss){
productBrandss.value = resultproductBrandss.data.value.productBrandss}

let variableproductCount={limit: 18,sort :{sellcount:-1}, skip :function(){
                      let pagequery =0;
                      try{
                       let pagePTem = parseInt(route.query.page);
                        if(pagePTem>0){
                        pagequery = (pagePTem -1)* 18;
                        }
                      }catch(e){
                      pagequery =0;
                      }
                      return pagequery
                      }(), query :{status:{eq:true},}};

const resultproductCount = await useAsyncQuery(gql` query    
 countProduct($query: productCountInput){
     productCount:  countProduct(query:$query)
  
}`,variableproductCount);
if(resultproductCount.data.value&&resultproductCount.data.value.productCount){
productCount.value = resultproductCount.data.value.productCount}

let variableproductLienQuans={limit: 18,sort :{sellcount:-1,createdAt:-1}, skip :function(){
                      let pagequery =0;
                      try{
                       let pagePTem = parseInt(route.query.page);
                        if(pagePTem>0){
                        pagequery = (pagePTem -1)* 18;
                        }
                      }catch(e){
                      pagequery =0;
                      }
                      return pagequery
                      }(), query :{status:{eq:true},}};

const resultproductLienQuans = await useAsyncQuery(gql` query    
 products($query: productFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     productLienQuans:  products(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name image price slug productBrands{ _id  createdAt  updatedAt  name  slug  banner  thumbnail  description  status  arrayMediaPlus  widthBanner  highlight  orderNumber  } marketPrice sellcount 
        }

}`,variableproductLienQuans);
if(resultproductLienQuans.data.value&&resultproductLienQuans.data.value.productLienQuans){
productLienQuans.value = resultproductLienQuans.data.value.productLienQuans}

let variablewebsiteConfig={};

const resultwebsiteConfig = await useAsyncQuery(gql` query {  websiteConfig {
                seoTitle seoKeywords seoDescription logo 
        } }`,variablewebsiteConfig);
if(resultwebsiteConfig.data.value&&resultwebsiteConfig.data.value.websiteConfig){
websiteConfig.value = {...resultwebsiteConfig.data.value.websiteConfig}}








        //2 afterData
        
     if(route.query['page']){
                                    let value =  route.query['page'];
                                    if(value ){
                                    page.value = value  ;
                                    }
                              }
;page.value='1'
    


        


        

        



        
        const methods=()=>{
            
        }

        
        mapSpBanChay.value=new Map(productLienQuans.value?.map(item=>[item._id,item])||[]);loadDatawebMedias()
        





  // watch



    











    //meta
        

        const meta = [  {name: "theme-color" ,content:"#fff"}];
        let title = '';

    title =''


         title= websiteConfig?.value.seoTitle;
            meta.push({ name: 'title', content:websiteConfig?.value.seoTitle });
            
            meta.push({ name: 'description', content:websiteConfig?.value.seoDescription });
            
            meta.push({ name: 'keywords', content:websiteConfig?.value.seoKeywords });
            
            meta.push({ name: 'og:image', content:websiteConfig?.value.logo });
            
            meta.push({ name: 'geo.region', content:'VN' });
            
            meta.push({ name: 'geo.placename', content:'Ho Chi Minh City' });
            


   const bodyAttrs ={};
    const  htmlAttrs={};
    



        useHead({
            title: title,
            viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
            charset: 'utf-8',
            meta: meta,
            htmlAttrs:htmlAttrs,
            bodyAttrs:bodyAttrs,
            script: [
                    
            ],
            
            link:[

                
                { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.min.css' }, 
                
                
                
                { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css' }, 
               
                    
            ]
            
        })
    


</script>


<!--false-->
<script>


    
               import { Grid,Autoplay,Navigation,Pagination,Keyboard } from 'swiper';
              import { Swiper, SwiperSlide } from "swiper/vue";
               import "swiper/css";
                 import 'swiper/css/grid';
                   import "swiper/css/pagination";
              import "swiper/css/navigation";
               
    export default {
        name: "-desktop",

        
        components: {
            Swiper,
 SwiperSlide,
},
        

        data(){
            return {

                }
            },
                methods:{




    }

        }
</script>

//update in Mon Sep 23 2024 23:51:15 GMT+0700 (Indochina Time) by loidirieng
